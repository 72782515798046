import React          from 'react';
import ReactDOM       from 'react-dom';
import moment         from 'moment';

import 'core-js/features/set/map';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-promise';
import 'bootstrap';

import './styles/application.scss';

import CinqHeures from './scenes/application';

document.addEventListener('DOMContentLoaded', () => {
  moment.locale('fr');
  ReactDOM.render(
    <CinqHeures />,
    document.getElementById('react-root')
  );
});
