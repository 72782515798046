import React                                                      from "react";
import { BrowserRouter as Router, Route, Switch }                 from "react-router-dom";
import { Helmet }                                                 from "react-helmet";
import SmartBanner                                                from "react-smartbanner";
import WithStore                                                  from "application/store/with_store";
import WithConfig                                                 from "application/components/application/with_config";
import PodcastsScene                                              from "application/scenes/podcasts";
import PodcastsAllYearsScene                                      from "application/scenes/podcasts/all_years";
import HomeScene                                                  from "application/scenes/home";
import MoviesShowScene                                            from "application/scenes/movies/show";
import MoviesByScoreScene                                         from "application/scenes/movies/by_score";
import OnNavigationChange                                         from "application/services/on_navigation_change";
import Navigation                                                 from "application/components/navigation";
import PlayerContainer                                            from "application/components/player/container";
import { Container }                                              from "reactstrap";

import SocialImage from "application/images/social.png";

import "react-smartbanner/dist/main.css";

const CinqHeures = () => (
  <WithStore>
    <WithConfig>
      <SmartBanner title="5heures" button="Voir" storeText={ { ios: "Sur l'App Store', android: 'Sur Google Play" } } price={ { ios: "Gratuit", android: "Gratuit" } } position="top" />
      <meta property="og:type" content="article" />
      <Helmet>
        <title>5heures | Toutes les critiques d'Hugues et toutes les digressions de Rudy</title>
        <meta name="description" content="Retrouvez toutes les critiques d'Hugues, les albums découverte et les digressions de Rudy, les COD, etc" />
        <meta property="og:title" content="5heures | Toutes les critiques d'Hugues et toutes les digressions de Rudy" />
        <meta property="og:description" content="Retrouvez toutes les critiques d'Hugues, les albums découverte et les digressions de Rudy, les COD, etc" />
        <meta property="og:image" content={ SocialImage } />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="391974958040425" />
        <meta property="og:url" content="https://5heures.be" />
        <link rel="canonical" href={ `${window.location.protocol}//${window.location.host}${window.location.pathname}` } />
      </Helmet>
      <Router basename="/">
        <React.Fragment>
          <Navigation />
          <OnNavigationChange />
          <Container fluid>
            <Switch>
              <Route
                exact
                path="/"
                render={ HomeScene }
              />
              <Route exact path="/films/score/:score">
                <MoviesByScoreScene />
              </Route>
              <Route exact path="/films/:slug">
                <MoviesShowScene />
              </Route>
              <Route
                exact
                path="/:kind/toutes-les-annees"
                render={ PodcastsAllYearsScene }
              />
              <Route
                exact
                path="/:kind/:year"
                render={ PodcastsScene }
              />
            </Switch>
          </Container>
          <PlayerContainer />
        </React.Fragment>
      </Router>
    </WithConfig>
  </WithStore>
);

export default CinqHeures;
