import React, { useEffect, useState } from "react";
import PropTypes                      from "prop-types";
import { Link }                       from "react-router-dom";
import { getMovies }                  from "application/requests/movie";
import { FontAwesomeIcon }            from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight }        from "@fortawesome/free-solid-svg-icons";
import Loader                         from "application/components/ui/loader";
import MoviesList                     from "application/components/movies/list";

import "./four_stars_movies.scss";

const HomeFourStarsMovies = () => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    getMovies({ score: 4, per_page: 6 }).then((response) => {
      setMovies(response.movies);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      { loading && <Loader /> }
      { !loading && (
        <div className="home-four-stars">
          <MoviesList movies={ movies } />
          <div className="text-right">
            <Link to="/films/score/4-etoiles">
              Voir tout
              <FontAwesomeIcon icon={ faLongArrowAltRight } className="ml-2" />
            </Link>
          </div>
        </div>
      ) }
    </React.Fragment>
  );
};

export default HomeFourStarsMovies;
