import React                   from "react";
import PropTypes               from "prop-types";
import HomeLastPodcasts        from "application/components/home/last_podcasts";
import HomeFourStarsMovies     from "application/components/home/four_stars_movies";
import SearchField             from "application/components/search_field";
import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import { faStar }              from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "reactstrap";

import "./index.scss";

import LogoRtbf       from "application/images/logo-rtbf.png";
import LogoLaPremiere from "application/images/logo-lapremiere.png";
import TMDBLogo       from "application/images/tmdb.svg";

const HomeScene = () => (
  <React.Fragment>
    <Container className="mt-5 home-container">
      <SearchField />
      <h2>Les derniers podcasts</h2>
      <HomeLastPodcasts />
      <h2>
        Les derniers films
        <FontAwesomeIcon icon={ faStar } className="ml-3 color-saffron" />
        <FontAwesomeIcon icon={ faStar } className="color-saffron" />
        <FontAwesomeIcon icon={ faStar } className="color-saffron" />
        <FontAwesomeIcon icon={ faStar } className="color-saffron" />
      </h2>
      <HomeFourStarsMovies />
      <Row className="align-items-center mt-2 mb-5">
        <Col md="4" xs="12">
          <img src={ LogoRtbf } alt="Logo RTBF" className="logo img-fluid" />
        </Col>
        <Col md="4" xs="12">
          <img src={ LogoLaPremiere } alt="Logo La Première" className="logo ml-md-5 mt-4 mb-4 mt-md-0 mb-md-0 align-self-center img-fluid" />
        </Col>
        <Col className="ml-0 ml-md-auto mt-5 mt-md-0 px-4" md="5" xs="12">
          <a href="https://www.themoviedb.org/" target="_blank" rel="noopener noreferrer"><img src={ TMDBLogo } alt="Logo The Movie Database" className="img-fluid mb-3" /></a>
          <p className="tmdb-disclaimer">This product uses the TMDb API but is not endorsed or certified by TMDb.</p>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default HomeScene;
