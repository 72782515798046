import React, { useState }      from "react";
import PropTypes                from "prop-types";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useSelector }          from "react-redux";
import classNames               from "classnames";
import { Collapse, Navbar, NavbarToggler,
         Nav, UncontrolledDropdown, DropdownToggle, NavItem,
         DropdownMenu, DropdownItem, NavLink as BootstrapNavLink } from "reactstrap";

import "./index.scss";

import LogoImg from "application/images/logo.svg";
import LogoImgSmall from "application/images/5.svg";

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(true);

  const config = useSelector(state => state.application.config);
  const location = useLocation();

  const toggleNavbar = () => setCollapsed(!collapsed);

  const isMainMenuActive = path => (
    location.pathname.startsWith(`/${path}`)
  );

  const isMoviesLinkIsActive = match => (
    location.pathname.startsWith("/films/score")
  );

  return (
    <React.Fragment>
      <Navbar color="dark" dark expand="md" className="application-nav">
        <Link className="ml-2 ml-xl-4 navbar-brand" to="/">
          <img width="190" src={ LogoImg } alt="5heures" className="d-none d-xl-block" />
          <img height="30" src={ LogoImgSmall } alt="5heures" className="d-xl-none" />
        </Link>
        <NavbarToggler onClick={ toggleNavbar } className="mr-2" />
        <Collapse isOpen={ !collapsed } navbar>
          <Nav className="ml-2 ml-xl-4 mr-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className={ classNames({ active: isMainMenuActive("le-podcast") }) }>
                Le podcast
              </DropdownToggle>
              <DropdownMenu>
                { config.years_for_kinds.cinq_heures.map(year => (
                  <DropdownItem key={ `cinq-heures-${year}` }>
                    <NavLink to={ `/le-podcast/${year}` } className="nav-link" activeClassName="active">
                      { year }
                    </NavLink>
                  </DropdownItem>
                )) }
                <DropdownItem divider />
                <DropdownItem>
                  <NavLink to="/le-podcast/toutes-les-annees" className="nav-link" activeClassName="active">
                    Toutes les années
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className={ classNames({ active: isMainMenuActive("5heures-du-soir") }) }>
                5H du soir
              </DropdownToggle>
              <DropdownMenu>
                { config.years_for_kinds.cinq_heures_du_soir.map(year => (
                  <DropdownItem key={ `5heures-du-soir-${year}` }>
                    <NavLink to={ `/5heures-du-soir/${year}` } className="nav-link" activeClassName="active">
                      { year }
                    </NavLink>
                  </DropdownItem>
                )) }
                <DropdownItem divider />
                <DropdownItem>
                  <NavLink to="/5heures-du-soir/toutes-les-annees" className="nav-link" activeClassName="active">
                    Toutes les années
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className={ classNames({ active: isMainMenuActive("critic-on-demand-live") }) }>
                COD Live
              </DropdownToggle>
              <DropdownMenu>
                { config.years_for_kinds.cod_live.map(year => (
                  <DropdownItem key={ `cod-live-${year}` }>
                    <NavLink to={ `/critic-on-demand-live/${year}` } className="nav-link" activeClassName="active">
                      { year }
                    </NavLink>
                  </DropdownItem>
                )) }
                <DropdownItem divider />
                <DropdownItem>
                  <NavLink to="/critic-on-demand-live/toutes-les-annees" className="nav-link" activeClassName="active">
                    Toutes les années
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            { config.years_for_kinds.other.length > 0 && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className={ classNames({ active: isMainMenuActive("5heures-autre") }) }>
                  Autre
                </DropdownToggle>
                <DropdownMenu>
                  { config.years_for_kinds.other.map(year => (
                    <DropdownItem key={ `5heures-autre-${year}` }>
                      <NavLink to={ `/5heures-autre/${year}` } className="nav-link" activeClassName="active">
                        { year }
                      </NavLink>
                    </DropdownItem>
                  )) }
                  <DropdownItem divider />
                  <DropdownItem>
                    <NavLink to="/5heures-autre/toutes-les-annees" className="nav-link" activeClassName="active">
                      Toutes les années
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) }
            <NavItem className="divider">|</NavItem>
            <NavItem>
              <NavLink to="/films/score/4-etoiles" className="nav-link" activeClassName="active" isActive={ isMoviesLinkIsActive }>
                Les films
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar className="ml-4">
            <NavItem>
              <BootstrapNavLink href="https://www.iubenda.com/privacy-policy/89562761" target="_blank">
                Politique de confidentialité
              </BootstrapNavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default Navigation;
